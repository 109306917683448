import WebComponent from '../../WebComponent.js'
import { html } from '../../_snowpack/pkg/lit-html.js'
import Navigation from '../Navigation/Navigation.js'

export default class SearchBar extends WebComponent {

    static get EVENT_KEYS() {
        return {
            SEARCH_CHANGED: 'search-changed'
        }
    }

    connectedCallback() {
        this.render();
    }

    get translationFile() {
        return './views/SearchBar/i18n.json'
    }

    get cssFile() {
        return './views/SearchBar/style.css';
    }

    getTemplate() {
        return html`
            <link rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/divider.css"
                integrity="sha256-5Vp89DiwHtBSkiLE+c/FyeFJCCHKNYLGPDblXAuj4Ng="
                crossorigin="anonymous">
            <link rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                crossorigin="anonymous">
            <link rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/divider.css"
                integrity="sha256-5Vp89DiwHtBSkiLE+c/FyeFJCCHKNYLGPDblXAuj4Ng="
                crossorigin="anonymous">
            <div id="customer-search">
                <p>${this.i18n.translate('recalls.search.intro')}</p>
                <div id="searchbar">
                    <a id="search_button" class="item">
                        <i class="search icon"></i>
                    </a>
                    <input
                        id="search"
                        type="search"
                        @change="${e => {
                            send(SearchBar.EVENT_KEYS.SEARCH_CHANGED, e.target.value)
                        }}"/>
                </div>
                <div class="ui divider"></div>
            </div>
        `
    }

}

customElements.define('customer-access-search-bar', SearchBar)