import WebComponent from '../../WebComponent.js';
import {html} from '../../_snowpack/pkg/lit-html.js';
import Navigation from '../Navigation/Navigation.js';
import Recalls from '../Recalls/Recalls.js';
import NoContent from '../NoContent/NoContent.js';
import Detail from '../Detail/Detail.js';
import Slider from '../Detail/Slider.js';
import Impressum from '../Impressum/Impressum.js';
import Installer from '../Installer/Installer.js';
import Language from '../Language/Language.js';
import WarningSubscription from "../WarningSubscription/WarningSubscription.js";
import WarningSubscriptionActivationDeactivation
    from "../WarningSubscription/ActivationDeactivation/WarningSubscriptionActivationDeactivation.js";
import WarningSubscriptionConfirmation from "../WarningSubscription/Confirmation/WarningSubscriptionConfirmation.js";

export default class MainView extends WebComponent {

    connectedCallback() {
        this.oldChild = null
        this.navigationListener = e => {
            this.loadView(e.detail)
        };

        document.addEventListener(Navigation.EVENT_KEYS.NAVIGATION_HAPPEND, this.navigationListener)

        this.loadView(getNavigationHash())
    }

    disconnectedCallback() {
        document.removeEventListener(Navigation.EVENT_KEYS.NAVIGATION_HAPPEND, this.navigationListener)
    }

    loadView(linkName) {
        let newChild;
        if (linkName === 'Recalls') {
            newChild = new Recalls();
        } else if (linkName === 'Search') {
            newChild = new Recalls({mode: 'search'});
        } else if (linkName === 'Impressum') {
            newChild = new Impressum();
        } else if (linkName === 'Installer') {
            newChild = new Installer();
        } else if (linkName === 'Language') {
            newChild = new Language();
        } else if (/Recalls\/(\d+){1}/.test(linkName)) {
            const group = /Recalls\/(\d+){1}/.exec(linkName);
            newChild = new Detail(group[1]);
        } else if (/Images\/(\d+){1}/.test(linkName)) {
            const group = /Images\/(\d+){1}/.exec(linkName);
            newChild = new Slider(group[1]);
        } else if (linkName === '') {
            newChild = new Recalls();
        } else if (linkName === 'WarningSubscription') {
            newChild = new WarningSubscription();
        } else if (linkName.startsWith('WarningSubscription/activate')) {
            newChild = new WarningSubscriptionActivationDeactivation(true);
        } else if (linkName.startsWith('WarningSubscription/deactivate')) {
            newChild = new WarningSubscriptionActivationDeactivation(false);
        } else if (linkName === 'WarningSubscription/confirmation') {
            newChild = new WarningSubscriptionConfirmation();
        } else {
            newChild = new NoContent();
        }

        if (this.oldChild) {
            this.root.replaceChild(newChild, this.oldChild)
        } else {
            this.root.appendChild(newChild)
        }

        this.oldChild = newChild
    }

    getTemplate() {
        return html`
        `
    }
}

customElements.define('customer-access-main-view', MainView)