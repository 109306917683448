import WebComponent from '../../WebComponent.js';
import { html } from '../../_snowpack/pkg/lit-html.js';
import RecallService from './RecallService.js';
import Recall from './Recall.js';
import I18n from '../../i18n.js';
import QuickNavigation from '../QuickNavigation/QuickNavigation.js';
import SearchBar from '../SearchBar/SearchBar.js'
import BasicPagination from '../Paging/BasicPagination.js'


export default class Recalls extends WebComponent {

    constructor(props = { mode: '' }) {
        super();
        this.props = props;
        this.prevPageYOffset = window.pageYOffset;
        this.selectedPage = 1;
        this.pagination = {};
        this.entries = [];
    }

    connectedCallback() {
        this.wirtschaftszweigId = isNaN(sessionStorage.getItem('wirtschaftszweigId')) ? null : sessionStorage.getItem('wirtschaftszweigId');
        this.searchQuery = null
        sessionStorage.setItem('wirtschaftszweigId', null);

        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, _ => {
            this.render();
        });
        this.wirtschaftszweigFilterChangedListener = e => {
            this.wirtschaftszweigId = e.detail
            this.reload();
        };
        this.searchQueryChangedListener = e => {
            this.searchQuery = e.detail;
            this.selectedPage = 1;
            this.reload();
        };
        this.pageChangedListener = e => {
            this.selectedPage = e.detail.newPage;
            this.reload();
            window.scrollTo({ top: 0})
        }
        document.addEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
        document.addEventListener(SearchBar.EVENT_KEYS.SEARCH_CHANGED, this.searchQueryChangedListener);
        document.addEventListener(BasicPagination.EVENT_KEYS.PAGE_CHANGED_EVENT, this.pageChangedListener);

        this.reload();
    }

    disconnectedCallback() {
        document.removeEventListener(QuickNavigation.EVENT_KEYS.WIRTSCHAFTSZWEIG_FILTER_CHANGED, this.wirtschaftszweigFilterChangedListener);
        document.removeEventListener(SearchBar.EVENT_KEYS.SEARCH_CHANGED, this.searchQueryChangedListener);
        document.removeEventListener(BasicPagination.EVENT_KEYS.PAGE_CHANGED_EVENT, this.pageChangedListener);
        send(QuickNavigation.EVENT_KEYS.DEACTIVATE_FILTER_FLAG, null);
    }

    reload() {
        let params = [];
        params.push({ key: 'page', value: this.selectedPage })
        if (this.wirtschaftszweigId) {
            params.push({ key: 'wirtschaftszweigId', value: this.wirtschaftszweigId })
        }
        if (this.searchQuery) {
            params.push({ key: 'searchQuery', value: this.searchQuery })
            params.push({ key: 'searchLanguage', value: getLanguage() })
        }
        new RecallService()
            .retrieveAll(params)
            .then(recalls => {
                if(!Array.isArray(recalls.entries)) return;
                this.entries = recalls.entries;

                this.pagination.page = recalls.page;
                this.pagination.pages = recalls.pages;
                this.pagination.pageSize = recalls.pageSize;
                this.pagination.count = recalls.count;

                this.render();
            })
    }

    get translationFile() {
        return './views/Recalls/i18n.json'
    }

    get cssFile() {
        return './views/Recalls/style.css';
    }

    navigateToRecall(id) {
        window.location.hash = `Recalls/${id}`;
    }

    toEntry(entry) {
        return html`
            <div style="min-height:${this.itemMinHeight};"
                @click="${_ => this.navigateToRecall(entry.id)}"
                class="item">
                <div class="image" style="margin:5px">
                    <img
                    alt="${entry.image.alt}"
                    src="${entry.image.url}"
                    >
                </div>
                <div class="content">
                    <a class="header">${entry.marktaufsichtsbehoerde} | ${entry.meta}</a>
                    <div class="description">
                        <p>${entry.header}</p>
                    </div>
                    <p class="lead">${entry.description}</p>
                </div>
            </div>
            <div class="ui divider"></div>
    `
    }

    getEntries() {
        if (this.entries.length === 0) {
            return this.i18n.translate('recalls.no.entries.found')
        }
        return this.entries
            .map(entry => this.toEntry(new Recall(entry)))
    }

    get cssLinks() {
        return html`
            <link rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/divider.css"
                integrity="sha256-5Vp89DiwHtBSkiLE+c/FyeFJCCHKNYLGPDblXAuj4Ng="
                crossorigin="anonymous">
            <link rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.css"
                integrity="sha256-EyIocnfi8LE28Ogio6vh1jzAztY8lXFrHvAGviJ7cVQ="
                crossorigin="anonymous">
        `
    }

    getTemplate() {
        return this.props.mode === 'search'
            ? html`
                ${this.cssLinks}
                <customer-access-search-bar></customer-access-search-bar>
                <div id="items" class="ui items">
                ${this.getEntries()}
                    <basic-pagination
                            page="${this.pagination.page}"
                            pages="${this.pagination.pages}"
                            count="${this.pagination.count}"></basic-pagination>
                </div>
            `
            : html`
                ${this.cssLinks}
                <div id="items" class="ui items">
                ${this.getEntries()}
                    <basic-pagination
                            page="${this.pagination.page}"
                            pages="${this.pagination.pages}"
                            count="${this.pagination.count}"></basic-pagination>
                </div>
            `
    }
}

customElements.define('customer-access-entry', Recalls)